window._ = require('lodash');
window.Popper = require('@popperjs/core');

try {
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import PhotoSwipeLightbox from 'photoswipe/lightbox';
window.PhotoSwipeLightbox = PhotoSwipeLightbox;

import PhotoSwipe from 'photoswipe';
window.PhotoSwipe = PhotoSwipe;